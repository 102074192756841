import React from "react";
import { Route, Redirect } from "react-router-dom";
import Config from "../../../config";

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}


  export default ({ component: C, props: cProps, ...rest }) => {
    const redirect = querystring("redirect");
    const pathname = rest.location.pathname;
    return (
      <Route
        {...rest}
        render={props =>
          (!cProps.isAuthenticated || ["/checkout/error", "/checkout/success"].includes(pathname))
            ? <C {...props} {...cProps} setpassword={rest.setpassword} />
            : <Redirect
                to={redirect === "" || redirect === null ? Config.REACT_APP_DEFAULT_LANDING_URI : redirect}
              />}
      />
    );
  };
