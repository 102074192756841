import React, { Component, Suspense } from "react";
import { Switch, withRouter } from "react-router-dom";
import Loadable from "react-loadable";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

import { loadAuthUserFromSession } from "../store/actions/authentication/auth";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Row, Col, Button } from "react-bootstrap";

import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Label, Alert } from "reactstrap";

import "../../node_modules/font-awesome/scss/font-awesome.scss";

import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";

import AuthRouter from "../route";

import { GetUserByAccountId } from "../store/actions/ewallet/users";
import { UpdateAccount } from "../store/actions/ewallet/accounts";

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
    };
  }

  userHasAuthenticated = (authenticated) => {
    let { user } = this.props.auth;

    this.setState({ isAuthenticated: authenticated });
    this.props.loadInitialList(user.id);
  };

  async componentDidMount() {
    try {
      //find the session on sessionStorage
      if (
        localStorage.getItem("ewalletsession") &&
        localStorage.getItem("ewallet_userinfo")
      ) {
        //this happens when user reloads
        this.userHasAuthenticated(true);

        //get the data from auth user
        this.props.loadAuthUserFromSession();
      }
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

    //set authentication here...
    this.setState({ isAuthenticating: false });
  }

  handleSubmit(event, error, values) {
    if (error.length > 0) return;
    if (values.password !== values.password2) {
      alert("Password did not match!");
      return;
    }

    const { account } = this.props.auth;

    this.props.UpdateAccount(
      {
        password: values.password,
        isTempPassword: false,
      },
      account.id,
      true
    );
  }

  redirectToKYCSubmission(onClose) {
    onClose();

    this.props.redirectToKYC();

    let { user } = this.props.auth;

    this.props.history.push(`/customers/kyc/${user.id}`);
  }

  componentDidUpdate(prevProps) {
    let { accessToken, user, account, redirectToKYCSubmission } =
      this.props.auth;
    // let { userInformation } = this.props.users;

    if (accessToken !== null) {
      if (prevProps.auth.accessToken !== accessToken) {
        this.props.loadInitialList(user.id);
      }
    }

    if (account && account.isTempPassword) {
      // alert('password need to be change');
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>Welcome to PEP Wallet!</h1>
              <Alert color={"info"} isOpen={true}>
                <p>
                  Please reset your password to be able to access your account
                  completely!
                </p>
              </Alert>
              <AvForm
                onSubmit={this.handleSubmit.bind(this)}
                ref={(f) => (this.form = f)}
              >
                <Row>
                  <Col md={7}>
                    <AvGroup>
                      <Label for="password" className="required">
                        New Password
                      </Label>
                      <AvField name="password" type="password" required />
                    </AvGroup>
                    <AvGroup>
                      <Label for="password" className="required">
                        Confirm Password
                      </Label>
                      <AvField
                        name="password2"
                        type="password"
                        required
                        errorMessage="Password did not match"
                      />
                    </AvGroup>
                  </Col>
                </Row>
              </AvForm>

              <Button
                onClick={this.props.logoutAccount}
                className="btn-icon m-r-5"
                variant={"outline-secondary"}
              >
                Logout
              </Button>
              <Button
                onClick={() => {
                  this.form.submit();
                  // onClose();
                }}
                className="btn-icon"
                variant={"outline-info"}
              >
                Reset Password
              </Button>
            </div>
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });

      return;
    } else if (
      account &&
      account.customer &&
      account.customer.status === "kyc_pending" &&
      account.role === "customer" &&
      !redirectToKYCSubmission
    ) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>Welcome to PEP Wallet!</h1>
              <Alert color={"info"} isOpen={true}>
                <div className="container">
                  <p>
                    To comply with regulation each participant will have to go
                    through indentity verification (KYC/AML) to prevent fraud
                    causes. Please, complete our fast and secure verification
                    process to participate in our wallet ecosystem!
                  </p>
                </div>
              </Alert>
              <Button
                onClick={this.redirectToKYCSubmission.bind(this, onClose)}
                className="btn-icon m-r-5"
                variant={"outline-warning"}
              >
                Click here to complete KYC
              </Button>
              <Button
                onClick={this.props.logoutAccount}
                className="btn-icon m-r-5"
                variant={"outline-secondary"}
              >
                Logout account to reflect changes
              </Button>
            </div>
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  }

  handleLogout = (event) => {
    localStorage.clear();
    this.userHasAuthenticated(false);
    this.props.history.push("/signin");
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
    };

    const headerProps = {
      handleLogout: this.handleLogout,
    };

    if (!this.state.isAuthenticating && this.state.isAuthenticated) {
      return (
        <Aux>
          <ScrollToTop>
            <Suspense fallback={<Loader />}>
              <Switch>
                <AdminLayout
                  AppProps={headerProps}
                  childProps={childProps}
                  {...this.props}
                />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Aux>
      );
    } else {
      return (
        <Aux>
          <AuthRouter childProps={childProps} {...this.props} />
        </Aux>
      );
    }
  }
}

const mapStateToProps = (state) => {
  let { REACT_APP_STAGE } = process.env;

  let appState =
    REACT_APP_STAGE === "development"
      ? {
          ...state,
        }
      : {};

  appState = {
    ...appState,
    ...state.accounts,
    auth: { ...state.auth },
  };

  return appState;
};

const mapDispatchToProps = (dispatch) => {
  return {
    startKyc: () => {
      dispatch({ type: "START_KYC" });
    },
    loadInitialList: (accountId) => {
      dispatch(GetUserByAccountId(accountId));
      // dispatch(GetAccount(accountId))
    },
    loadAuthUserFromSession: () => {
      dispatch(loadAuthUserFromSession());
    },
    logoutAccount: () => {
      dispatch({ type: "LOGOUT" });
    },
    UpdateAccount: (objParams, accountId, changePassword) => {
      dispatch(UpdateAccount(objParams, accountId, changePassword));
    },
    redirectToKYC: () => {
      dispatch({ type: "REDIRECTED_TO_KYC_SUBMISSTION" });
    },
  };
};

export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
);
