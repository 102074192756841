import React from "react";
import { NavLink } from "react-router-dom";
import queryString from "query-string";

import "./../../../assets/scss/style.scss";
import Aux from "../../../hoc/_Aux";
//import logoDark from '../../../assets/images/emergewallet.png';
// import logoDark from '../../../assets/scss/themes/images/main_logo.png';

import logoDark from "../../../assets/scss/themes/images/logo_white.png";

import { Alert } from "reactstrap";
import { Button } from "react-bootstrap";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import { connect } from "react-redux";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import LoadingOverlay from "react-loading-overlay";

import {
  setUserLoading,
  CreateUser,
  dismissUserError,
} from "../../../store/actions/ewallet/users";

const initStates = {
  isLoading: false,
  email: "",
  password: "",
  confirmpassword: "",
  phoneNumber: "",
  firstname: "",
  middleName: "",
  lastname: "",
  gender: "Male",
};

class SignUp1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = initStates;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Confirm your action</h1>
            <p>Are you sure you want to register this account?</p>
            <Button
              onClick={onClose}
              className="btn-icon m-r-5"
              variant={"outline-secondary"}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                let params = queryString.parse(this.props.location.search);
                if (params && params.ref) formData.referredByToken = params.ref;

                this.props.setUserLoading("Processing Request...");
                this.props.createUser(formData);
                this.setState(initStates);
                onClose();
              }}
              className="btn-icon"
              variant={"outline-info"}
            >
              Confirm
            </Button>
          </div>
        );
      },
    });
  };

  render() {
    return (
      <Aux>
        <LoadingOverlay
          active={this.props.loading}
          spinner
          text={"Account creation in progress..."}
        >
          <div className="page-ath-wrap">
            <div className="page-ath-content">
              <div className="page-ath-header text-center">
                <a href="/" className="page-ath-logo">
                  <img height="250" src={logoDark} alt="logo" />
                </a>
              </div>
              <div className="page-ath-form">
                <h2 className="page-ath-heading">
                  Sign Up <small>to have your PEP Wallet wallet account</small>
                </h2>
                <Alert
                  color={"danger"}
                  isOpen={this.props.error}
                  toggle={this.props.dismissError}
                >
                  {this.props.errorMsg}
                </Alert>
                <Alert
                  color={"success"}
                  isOpen={this.props.usertCreated}
                  toggle={this.props.dismissError}
                >
                  An email was sent to your registered email address to activate
                  your account.
                </Alert>
                <ValidationForm
                  autoComplete={false}
                  onSubmit={this.handleSubmit}
                  onErrorSubmit={this.handleErrorSubmit}
                >
                  <div className="input-item">
                    <TextInput
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      type="email"
                      required
                      errorMessage={{ required: "Username is required" }}
                      value={this.state.email}
                      onChange={this.handleChange}
                      autoComplete="off"
                      className="input-bordered"
                    />
                  </div>
                  <div className="input-item">
                    <TextInput
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder="Your Phone Number"
                      type="text"
                      required
                      errorMessage={{ required: "Phone number is required" }}
                      value={this.state.phoneNumber}
                      onChange={this.handleChange}
                      autoComplete="off"
                      className="input-bordered"
                    />
                  </div>
                  <div className="input-item">
                    <TextInput
                      name="firstname"
                      id="firstname"
                      type="text"
                      placeholder="First Name"
                      required
                      errorMessage={{ required: "First name is required" }}
                      value={this.state.firstname}
                      onChange={this.handleChange}
                      autoComplete="off"
                      className="input-bordered"
                    />
                  </div>
                  <div className="input-item">
                    <TextInput
                      name="middleName"
                      id="middleName"
                      type="text"
                      placeholder="Middle Name"
                      value={this.state.middleName}
                      onChange={this.handleChange}
                      autoComplete="off"
                      className="input-bordered"
                    />
                  </div>
                  <div className="input-item">
                    <TextInput
                      name="lastname"
                      id="lastname"
                      type="text"
                      placeholder="Last Name"
                      required
                      errorMessage={{ required: "Last name is required" }}
                      value={this.state.lastname}
                      onChange={this.handleChange}
                      autoComplete="off"
                      className="input-bordered"
                    />
                  </div>
                  <div className="input-item">
                    <TextInput
                      name="password"
                      id="password"
                      type="password"
                      placeholder="Password"
                      required
                      errorMessage={{ required: "Password is required" }}
                      value={this.state.password}
                      onChange={this.handleChange}
                      autoComplete="off"
                      className="input-bordered"
                    />
                  </div>
                  {this.props.loading ? (
                    <Button className="btn btn-primary btn-block" disabled>
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                      Loading...
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-primary btn-block">
                      Register
                    </Button>
                  )}
                </ValidationForm>
                <div className="gaps-2x"></div>
                <div className="gaps-2x"></div>
                <div className="form-note">
                  Already have an account?{" "}
                  <NavLink to="/auth/signin" className="f-w-400">
                    <strong>Sign in here</strong>
                  </NavLink>{" "}
                </div>
              </div>

              <div className="page-ath-footer">
                <ul className="footer-links">
                  <li>
                    <a href="regular-page.html">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="regular-page.html">Terms</a>
                  </li>
                  <li>&copy; 2021 PEP Wallet.</li>
                </ul>
              </div>
            </div>
            <div className="page-ath-gfx">
              <div className="w-100 d-flex justify-content-center">
                <div className="col-md-8">
                  {/* <img src={athGfx} alt="image" /> */}
                  <div class="main-banner-content">
                    <h1>
                      Send money, top-up mobile worldwide, pay bills, shop! An
                      all-in-one eWallet.
                    </h1>
                    <p>
                      With PEP wallet, you can securely handle all your payments
                      quickly with no required registration fees, minimum
                      balance, or even bank account.
                    </p>
                    <div class="banner-btn">
                      <a href="#" class="btn btn-primary">
                        Download PEP wallet now{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.users,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserLoading: () => {
      dispatch(setUserLoading());
    },
    createUser: (objValues) => {
      dispatch(CreateUser(objValues));
    },
    dismissError: () => {
      dispatch(dismissUserError());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp1);
