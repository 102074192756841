export default {
    items: [
        {
            id: 'navigation',
            title: 'Navigation',
            type: 'group',
            icon: 'icon-support',
            children: [
                {
                    id: 'dashboard-page',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/dashboard',
                    classes: 'nav-item',
                    icon: 'feather icon-sidebar'
                },
                {
                    id: 'Transfer-page',
                    title: 'Transfer',
                    type: 'item',
                    url: '/transfer',
                    classes: 'nav-item',
                    icon: 'feather icon-arrow-up-right'
                },
                {
                    id: 'transactions-page',
                    title: 'Transactions',
                    type: 'item',
                    url: '/transactions',
                    classes: 'nav-item',
                    icon: 'feather icon-list'
                },
                {
                    id: 'requestmoney-page',
                    title: 'Request Money',
                    type: 'item',
                    url: '/request-money',
                    classes: 'nav-item',
                    icon: 'feather icon-corner-left-up'
                },
                {
                    id: 'incomingrequest-page',
                    title: 'Incoming Request',
                    type: 'item',
                    url: '/incoming-request',
                    classes: 'nav-item',
                    icon: 'feather icon-corner-left-down'
                },
                // {
                //     id: 'topup-page',
                //     title: 'Topup',
                //     type: 'item',
                //     url: '/topup',
                //     classes: 'nav-item',
                //     icon: 'feather icon-check-circle'
                // },
                {
                    id: 'payment-page',
                    title: 'Payments',
                    type: 'item',
                    url: '/Payments',
                    classes: 'nav-item',
                    icon: 'feather icon-shopping-cart'
                },
                {
                    id: 'banking-page',
                    title: 'Emerge Bank',
                    type: 'item',
                    url: '/microfinance',
                    classes: 'nav-item',
                    icon: 'fa fa-building'
                },
                {
                    id: 'menu-level',
                    title: 'Settings',
                    type: 'collapse',
                    icon: 'feather icon-menu',
                    children: [
                        {
                            id: 'menu-level-users',
                            title: 'Profile',
                            type: 'item',
                            url: '/settings/profile'
                        },
                        {
                            id: 'menu-level-rewards',
                            title: 'Points',
                            type: 'item',
                            url: '/settings/rewards'
                        },
                        {
                            id: 'menu-level-redeemables',
                            title: 'Rewards',
                            type: 'item',
                            url: '/rewards'
                        },
                        {
                            id: 'menu-level-vouchers',
                            title: 'Vouchers',
                            type: 'item',
                            url: '/vouchers'
                        },
                        {
                            id: 'menu-level-cards',
                            title: 'Cards',
                            type: 'item',
                            url: '/settings/cards'
                        }
                    ]
                }
            ]
        }
    ]
}