import Config from "../../../config";

const initState = {
    open                   : false,
    error                  : false,
    errorMsg               : "",
    loading                : false,
    loading_image          : false,
    loadingText            : "Processing Request...",
    meta                   : Config.defaultMeta,
    data                   : [],
    selectedRequestMoney   : {},
    RequestMoneyInformation: {},
    accountCreated         : false,
    RequestMoneyId         : null,
    requestAnswered        : false,
    defaultSorted          : [
        {
            id  : "createdAt",
            desc: true
        }
    ]
};
export default function requestmoney(state = initState, action) {
    
    switch (action.type) {

        case "CLEAR_SELECTED_REQUEST_MONEY": 
            return {
                ...state,
                error               : false,
                loading             : false,
                loading_image       : false,
                RequestMoneyId      : null,
                selectedRequestMoney: {},
            };

                        
        case "FETCH_REQUEST_MONEY": 
            return {
                ...state,
                error        : false,
                loading      : false,
                loading_image: false,
                meta         : action.meta,
                data         : action.payload,
            };
            
        case "REQUEST_MONEY_LOADING": 
            return {
                ...state,
                loading      : true,
            };

        case "REQUEST_MONEY_LOADING_STOP": 
            return {
                ...state,
                error        : false,
                loading      : false,
                loading_image: false,
            };

        case "REQUEST_ACCEPTED_OR_REJECT": 
            return {
                ...state,
                error        : false,
                loading      : false,
                loading_image: false,
                requestAnswered: true
            };

        case "ERROR_REQUEST_MONEY": 
            return {
                ...state,
                error        : true,
                loading      : false,
                loading_image: false,
                errorMsg     : action.errorMsg.messages,
            };

        case "REQUEST_MONEY":
          return {
            ...state,
          };
                
    default:
        return state;
    }
    
}