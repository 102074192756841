import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { Alert } from "reactstrap";

import "../../assets/scss/style.scss";
import Aux from "../../hoc/_Aux";
//import logoDark from '../../assets/images/emergewallet.png';

// import logoDark from '../../assets/scss/themes/images/main_logo.png';
import logoDark from "../../assets/scss/themes/images/logo_white.png";

import {
  setLoading,
  activateAccount,
  dismissAuthError,
} from "../../store/actions/authentication/auth";

class Activation extends React.Component {
  componentDidMount() {
    this.props.setLoading();
    this.props.activateAccount(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.dismissAuthError();
  }

  render() {
    const { loading, error, errorMsg, dismissError, accountActivated } =
      this.props;

    return (
      <Aux>
        <LoadingOverlay
          active={loading}
          spinner
          text={"Activation in progress"}
        >
          <div className="auth-wrapper">
            <div
              className="auth-content text-center"
              style={{ marginBottom: "10%" }}
            >
              <img src={logoDark} alt="" className="img-fluid mb-4" />
              <div className="card">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="card-body">
                      <div className="text-center">
                        <h4 className="mb-4 f-w-400">Account Activation</h4>

                        <Alert
                          color={"danger"}
                          isOpen={error}
                          toggle={dismissError}
                        >
                          {errorMsg}
                        </Alert>

                        <Alert color={"success"} isOpen={accountActivated}>
                          Your account has successfully been activation and
                          verified. Please submit required information and
                          documents to proceed with KYC process
                        </Alert>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="gaps-2x"></div>
              <div className="gaps-2x"></div>
              <div className="form-note">
                Already have an account?{" "}
                <NavLink to="/auth/signin" className="f-w-400">
                  <strong>Sign in here</strong>
                </NavLink>{" "}
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: () => {
      dispatch(setLoading());
    },
    activateAccount: (activationToken) => {
      dispatch(activateAccount(activationToken));
    },
    dismissAuthError: () => {
      dispatch(dismissAuthError());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Activation);
