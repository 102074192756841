import React from 'react';
import ReactDOM from 'react-dom';
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from 'redux';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import { CookiesProvider } from "react-cookie";
import App from './App/index';
import * as serviceWorker from './serviceWorker';

// import reducer from './store/reducers/reducer';
import config from './config';

import './assets/scss/style.scss';

// combine all reducers
import root_reducer from "./store/reducers/root_reducer";

const store = createStore(root_reducer, applyMiddleware(thunk));

const app = (
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
