import React from "react";
import { NavLink } from "react-router-dom";

import "./../../../assets/scss/style.scss";

import Aux from "../../../hoc/_Aux";
import Breadcrumb from "../../../App/components/Breadcrumb";
//import logoDark from '../../../assets/images/emergewallet.png';
// import logoDark from '../../../assets/scss/themes/images/main_logo.png';

import logoDark from "../../../assets/scss/themes/images/logo_white.png";

import { Alert } from "reactstrap";
import { Button } from "react-bootstrap";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";

import { connect } from "react-redux";

import { SocialButton } from "../../../App/components";
import LoadingOverlay from "react-loading-overlay";

import {
  postLogin,
  dismissAuthError,
  setLoading,
  fetchCurrUser,
  postLoginViaSocialMedia,
} from "../../../store/actions/authentication/auth";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      password: "",
      saveCredentials: false,
      logged: false,
      user: {},
      currentProvider: "",
    };

    this.nodes = {};
  }

  componentDidMount() {
    //session error checking
    let strSessionError = localStorage.getItem("error");
    if (strSessionError) {
      this.props.sessionError(strSessionError);
      localStorage.removeItem("error");
    }

    if (localStorage.getItem("rememberme")) {
      this.setState({
        email: localStorage.getItem("username"),
        phonenumber: localStorage.getItem("username"),
        password: window.atob(localStorage.getItem("password")),
        saveCredentials: true,
      });
    }
  }

  handleCheckboxChange = (e, value) => {
    this.setState({
      [e.target.name]: value,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  saveCredentials = () => {
    this.setState((prevState) => ({
      saveCredentials: !prevState.saveCredentials,
    }));
  };

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();

    let { email, password, saveCredentials } = this.state;

    if (saveCredentials) {
      var encodedPass = window.btoa(password);
      localStorage.setItem("username", email);
      localStorage.setItem("password", encodedPass);
      localStorage.setItem("rememberme", true);
    } else {
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      localStorage.removeItem("rememberme");
    }

    //redux way for showing loading screen
    this.props.showLoading();

    //Redux Login functionality
    this.props.login(formData);
  };

  handleErrorSubmit = (e, formData, errorInputs) => {
    // console.log(e, formData, errorInputs);
  };

  componentDidUpdate(prevProps) {
    //after update simply redirect and build session data
    if (this.props.auth.token && this.props.auth.token.accessToken) {
      this.props.userHasAuthenticated(true);
    }
  }

  onLoginSuccess = (user) => {
    this.setState({
      logged: true,
      currentProvider: user._provider,
      user,
    });
    this.props.socialMediaLogins(user);
  };

  onLoginFailure = (err) => {
    console.error(err);
    this.setState({
      logged: false,
      currentProvider: "",
      user: {},
    });
  };

  onLogoutSuccess = () => {
    this.setState({
      logged: false,
      currentProvider: "",
      user: {},
    });
  };

  onLogoutFailure = (err) => {
    console.error(err);
  };

  logout = () => {
    const { logged, currentProvider } = this.state;

    if (logged && currentProvider) {
      this.nodes[currentProvider].props.triggerLogout();
    }
  };

  setNodeRef(provider, node) {
    if (node) {
      this.nodes[provider] = node;
    }
  }

  render() {
    return (
      <Aux>
        <Breadcrumb />
        <LoadingOverlay
          active={this.props.auth.socialMediaLoginLoading}
          spinner
          text={"Please wait..."}
        >
          <div className="page-ath-wrap">
            <div className="page-ath-content">
              <div className="page-ath-header text-center">
                <a href="/" className="page-ath-logo">
                  <img height="250" src={logoDark} alt="logo" />
                </a>
              </div>

              <div className="page-ath-form">
                <h2 className="page-ath-heading">
                  Sign in <small>with your PEP Wallet account</small>
                </h2>
                <Alert
                  color={"danger"}
                  isOpen={this.props.auth.error}
                  toggle={this.props.dismissError}
                >
                  {this.props.auth.errorMsg}
                </Alert>
                <ValidationForm
                  onSubmit={this.handleSubmit}
                  onErrorSubmit={this.handleErrorSubmit}
                >
                  <div className="input-item">
                    <TextInput
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      type="email"
                      required
                      errorMessage={{ required: "Username is required" }}
                      value={this.state.email}
                      onChange={this.handleChange}
                      autoComplete="off"
                      className="input-bordered"
                    />
                  </div>
                  <div className="input-item">
                    <TextInput
                      name="password"
                      id="password"
                      type="password"
                      placeholder="Password"
                      required
                      errorMessage={{ required: "Password is required" }}
                      value={this.state.password}
                      onChange={this.handleChange}
                      autoComplete="off"
                      className="input-bordered"
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="input-item text-left">
                      {/* <input className="input-checkbox input-checkbox-md" id="remember-me" type="checkbox"/><label for="remember-me">Remember Me</label></div> */}
                      <input
                        type="checkbox"
                        name="checkbox-fill-1"
                        id="remember-me"
                        className="input-checkbox input-checkbox-md"
                        checked={this.state.saveCredentials}
                        onClick={this.saveCredentials}
                        onChange={() => {}}
                      />
                      <label htmlFor="remember-me">Remember Me</label>
                    </div>
                    <div>
                      <NavLink to="/auth/reset-password">
                        Forgot password?
                      </NavLink>
                      <div className="gaps-2x"></div>
                    </div>
                  </div>
                  {this.props.auth.loading ? (
                    <Button className="btn btn-primary btn-block" disabled>
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                      Loading...
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-primary btn-block">
                      Sign In
                    </Button>
                  )}
                </ValidationForm>
                <div className="sap-text">
                  <span>Or Sign In With</span>
                </div>

                <ul className="row guttar-20px guttar-vr-20px">
                  <li className="col">
                    <SocialButton
                      provider="facebook"
                      appId="1185744481798955"
                      onLoginSuccess={this.onLoginSuccess}
                      onLoginFailure={this.onLoginFailure}
                      onLogoutSuccess={this.onLogoutSuccess}
                      getInstance={this.setNodeRef.bind(this, "facebook")}
                      styles={"btn btn-outline btn-dark btn-facebook btn-block"}
                      key={"facebook"}
                    >
                      <em className="fab fa-facebook-f"></em>
                      <span>Facebook</span>
                    </SocialButton>
                  </li>
                  <li className="col">
                    <SocialButton
                      provider="google"
                      appId="70595472710-0c7qm9mhj4k5jes96rci8cmkk7baiohs.apps.googleusercontent.com"
                      onLoginSuccess={this.onLoginSuccess}
                      onLoginFailure={this.onLoginFailure}
                      onLogoutSuccess={this.onLogoutSuccess}
                      onLogoutFailure={this.onLogoutFailure}
                      getInstance={this.setNodeRef.bind(this, "google")}
                      key={"google"}
                      scope={"https://www.googleapis.com/auth/user.gender.read"}
                      styles={"btn btn-outline btn-dark btn-google btn-block"}
                    >
                      <em className="fab fa-google"></em>
                      <span>Google</span>
                    </SocialButton>
                  </li>
                </ul>
                <div className="gaps-2x"></div>
                <div className="gaps-2x"></div>
                <div className="form-note">
                  Don’t have an account?{" "}
                  <NavLink to="/auth/signup" className="f-w-400">
                    <strong>Sign up here</strong>
                  </NavLink>{" "}
                </div>
              </div>

              <div className="page-ath-footer">
                <ul className="footer-links">
                  <li>
                    <a href="regular-page.html">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="regular-page.html">Terms</a>
                  </li>
                  <li>&copy; 2021 PEP Wallet.</li>
                </ul>
              </div>
            </div>
            <div className="page-ath-gfx">
              <div className="w-100 d-flex justify-content-center">
                <div className="col-md-8">
                  {/* <img src={athGfx} alt="image" /> */}
                  <div class="main-banner-content">
                    <h1>
                      Send money, top-up mobile worldwide, pay bills, shop! An
                      all-in-one eWallet.
                    </h1>
                    <p>
                      With PEP wallet, you can securely handle all your payments
                      quickly with no required registration fees, minimum
                      balance, or even bank account.
                    </p>
                    <div class="banner-btn">
                      <a href="#" class="btn btn-primary">
                        Download PEP wallet now{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    socialMediaLogins: (loginDetails) => {
      dispatch(postLoginViaSocialMedia(loginDetails));
    },
    login: (objCredentials) => {
      dispatch(postLogin(objCredentials));
    },
    dismissError: () => {
      dispatch(dismissAuthError());
    },
    showLoading: () => {
      dispatch(setLoading());
    },
    fetchCurrUser: (userId) => {
      dispatch(fetchCurrUser(userId));
    },
    sessionError: (strError) => {
      dispatch({
        type: "AUTH_FAILED",
        errorMsg: strError,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
