
import Config from "../../../config";

const initState = {
    open                  : false,
    error                 : false,
    errorMsg              : "",
    loading               : false,
    loading_image         : false,
    loadingText           : "Processing Request...",
    meta                  : Config.defaultMeta,
    data                  : [],
    selectedTransaction   : {},
    transactionInformation: {},
    accountCreated        : false,
    transactionId         : null,
    defaultSorted         : [
        {
            id  : "createdAt",
            desc: true
        }
    ]
};

export default function transactions(state = initState, action) {
    switch (action.type) {

        case "CLEAR_SELECTED_TRANSACTIONS": 
            return {
                ...state,
                error          : false,
                loading        : false,
                loading_image  : false,
                transactionId      : null,
                selectedTransaction:  {},
            };
            
        case "FETCH_TRANSACTIONS": 
            return {
                ...state,
                error        : false,
                loading      : false,
                loading_image: false,
                meta         : action.meta,
                data         : action.payload,
            };

        case "FETCH_A_TRANSACTION": 
            return {
                ...state,
                error          : false,
                loading        : false,
                loading_image  : false,
                transactionId         : action.transactionId,
                selectedTransaction: { ...action.payload },
            };

        case "TRANSACTION_LOADING": 
            return {
                ...state,
                loading      : true,
            };

        case "TRANSACTION_LOADING_STOP": 
            return {
                ...state,
                error        : false,
                loading      : false,
                loading_image: false,
            };

        case "ERROR_TRANSACTIONS": 
            return {
                ...state,
                error        : true,
                loading      : false,
                loading_image: false,
                errorMsg     : action.errorMsg.messages,
            };

        default: 
            return state;
    }
}