export default {
    items: [
        {
            id: 'navigation',
            title: 'Navigation',
            type: 'group',
            icon: 'icon-support',
            children: [
                {
                    id: 'dashboard-page',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/admin/dashboard',
                    classes: 'nav-item',
                    icon: 'feather icon-sidebar'
                },
                {
                    id: 'transasctions-page',
                    title: 'Transactions',
                    type: 'item',
                    url: '/transactions',
                    classes: 'nav-item',
                    icon: 'feather icon-list'
                },
                {
                    id: 'account-page',
                    title: 'Accounts',
                    type: 'item',
                    url: '/accounts',
                    classes: 'nav-item',
                    icon: 'feather icon-user-check'
                },
                {
                    id: 'menu-level-users',
                    title: 'Wallets Customers',
                    type: 'item',
                    url: '/customers',
                    classes: 'nav-item',
                    icon: 'feather icon-users'
                },
                {
                    id: 'menu-level-program',
                    title: 'Loyal Program',
                    type: 'item',
                    url: '/loyalty',
                    classes: 'nav-item',
                    icon: 'feather icon-award'
                },
                {
                    id: 'menu-level-rewads',
                    title: 'Rewards',
                    type: 'item',
                    url: '/rewards',
                    classes: 'nav-item',
                    icon: 'fa fa-trophy'
                }
            ]
        }
    ]
}