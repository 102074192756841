const tempConfig = {
    ...process.env
};

let config = {
  ...tempConfig,
  defaultMeta: {
    itemTotal   : process.env.REACT_APP_DEFAULT_PAGINATE_ITEM_TOTAL,
    pageNumber  : process.env.REACT_APP_DEFAULT_PAGINATE_PAGE_NUMBER,
    pageSize    : process.env.REACT_APP_DEFAULT_PAGINATE_PAGE_SIZE,
    pageTotal   : process.env.REACT_APP_DEFAULT_PAGINATE_PAGE_TOTAL,
    sort        : process.env.REACT_APP_DEFAULT_PAGINATE_SORT_FIED,
  },
  defaultPath   : "/ewallet",
  basename      : "", // only at build time to set, like ///ewallet/react/default
  layout        : "vertical", // vertical, horizontal
  collapseMenu      : true, // mini-menu
  layoutType        : "menu-dark", // menu-dark, menu-light
  headerBackColor   : "", // header-blue, header-red, header-purple, header-info, header-dark
  fullWidthLayout   : true,
  navFixedLayout    : true,
  headerFixedLayout : true,
};

export default config