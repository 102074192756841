// import { flattenPermissions } from '../../actions/helpers/permissionHelper'

//need for first render
const initState = {
    accessToken  : false,
    error        : false,
    reset_success: false,
    user         : {
        firstname  : null,
        middleName : null,
        lastname   : null,
        email      : null,
        phoneNumber: null,
        role       : null,
        createdAt  : null,
    },
    socialProfile          : {},
    loading                : false,
    loading_image          : false,
    socialMediaLoginLoading: false,
    accountActivated       : false,
    redirectToKYCSubmission: false
};

export default function auth(state = initState, action) {

    //let defaultUser = initState.user;

    switch (action.type) {

        case "REDIRECTED_TO_KYC_SUBMISSTION":
            return {
                ...state,
                error           : false,
                loading         : false,
                redirectToKYCSubmission: true
            }

        case "ACCOUNT_ACTIVATED": 
            return {
                ...state,
                error           : false,
                loading         : false,
                accountActivated: true
            }

        case "LOGIN_VIA_SOCIAL": 
            return {
                ...state,
                error                  : false,
                loading                : false,
                socialMediaLoginLoading: true,
                socialProfile          : { ...action.socialProfile }
            }

        case "SOCIAL_LOGIN_COMPLETE": 

            return {
                ...state,
                error                  : false,
                loading                : false,
                socialMediaLoginLoading: false,
                socialProfile          : { ...action.socialProfile }
            }
                
        case "LOGIN": 
            
            // let loginRefPermissions = flattenPermissions(action.user.permissions)
            
            return {
                ...state,
                error: false,
                ...action.auth,
                loading: false,
                user   : {
                    id         : action.user.id,
                    Firstname  : action.user.firstname,
                    Middlename : action.user.middlename || "",
                    Lastname   : action.user.lastname,
                    email      : action.user.email,
                    phoneNumber: action.user.phoneNumber,
                    role       : action.user.role,
                    createdAt  : action.user.createdAt
                },

            };

        case "AUTH_LOAD_SESSION": {

            // let sessionRefPermissions = flattenPermissions(action.user.permissions)
           
            return {
                ...state,
                error: false,
                ...action.auth,
                loading: false,
                user   : {
                    id         : action.user.id,
                    Firstname  : action.user.firstname,
                    Middlename : action.user.middlename || "",
                    Lastname   : action.user.lastname,
                    email      : action.user.email,
                    phoneNumber: action.user.phoneNumber,
                    role       : action.user.role,
                    createdAt  : action.user.createdAt
                },
            }
        }

        case "AUTH_LOADING": 

            return {
                ...state,
                error  : false,
                loading: true
            };

        case "AUTH_FAILED": 
            return {
                ...state,
                error   : true,
                errorMsg: action.errorMsg,
                loading : false
            };

        case "DISMISS_AUTH_ERROR": 
            return initState;

        case "LOGOUT": 

            let username   = localStorage.getItem('username');
            let password   = localStorage.getItem('password');
            let rememberme = localStorage.getItem('rememberme');

            localStorage.clear();

            if (rememberme) {
                localStorage.setItem('username', username);
                localStorage.setItem('password', password);
                localStorage.setItem('rememberme', rememberme);
            }

            window.location.href = "/auth/signin";

            return initState;

        default: 
            return state;
    }

}