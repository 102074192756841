import Config from "../../../config";

const initState = {
  open         : false,
  error        : false,
  errorMsg     : "",
  loading      : false,
  loading_image: false,
  loadingText  : "Processing Request...",
  meta         : Config.defaultMeta,
  data         : [],
  selectedUser : {},
  usertCreated : false,
  dropdownOpen : new Array(10).fill(false),
  userId       : null,
  accountId    : null,
  defaultSorted: [
    {
      id  : "createdAt",
      desc: true
    }
  ],
  userInformation: {},
  searchedUser   : {},
  startKycProcess: false,
  searchOnGoing  : true
};

export default function users(state = initState, action) {
  switch (action.type) {
    case "FETCH_USERS": 
      return {
        ...state,
        error        : false,
        data         : action.payload,
        meta         : action.meta,
        loading      : false,
        loading_image: false
      };
    case "FETCH_A_USER": 
      return {
        ...state,
        error        : false,
        loading      : false,
        loading_image: false,
        userId       : action.userId,
        selectedUser : { ...action.payload },
      };
    case "START_KYC": 
      return {
        ...state,
        startKycProcess: true
      };
    case "FETCH_A_USER_BY_ACCOUNT_NUMBER":
      return {
        ...state,
        error          : false,
        loading        : false,
        loading_image  : false,
        accountId      : action.userId,
        searchedUser   : { ...action.payload },
        startKycProcess: false
      };
    case "FETCH_A_USER_BY_ACCOUNT": 
      return {
        ...state,
        error          : false,
        loading        : false,
        loading_image  : false,
        userId         : action.userId,
        userInformation: { ...action.payload },
        startKycProcess: false
      };
    case "USER_CREATED": 
      return {
        ...state,
        error        : false,
        loading      : false,
        usertCreated : true,
        loading_image: false,
        selectedUser : { ...action.payload },
      };

    case "USER_LOADING": 
      return {
        ...state,
        loading: true,
      };
    case "USER_LOADING_STOP": 
      return {
        ...state,
        error        : false,
        loading      : false,
        loading_image: false,
      };

    case "CONFIRMED_USER_SELECTION": 
      return {
        ...state,
        searchOnGoing     : false,
      };

    case "ERROR_USERS": 
      return {
        ...state,
        error        : true,
        loading      : false,
        loading_image: false,
        errorMsg     : action.errorMsg.messages,
      };

    case "CLEAR_USER_SEARCH": 
      return {
        ...state,
        searchedUser     : {},
        searchOnGoing    : true,
        error            : false,
        loading          : false,
        loading_image    : false,
      };

    default: 
      return state;
  }
}
