import { combineReducers } from "redux";

import theme_reducer from './theme_reducer'
import notification from './notification';
import auth from './authentication/auth';
import users from './ewallet/users';
import accounts from './ewallet/accounts';
import loyalty from './ewallet/loyalty';
import requestmoney from './ewallet/requestmoney';
import ewallet from './ewallet/wallet';
import transactions from './ewallet/transactions';
import dashboard from './ewallet/dashboard';

import redux_actions from './redux_actions';

export default combineReducers({
    theme_reducer,
    notification,
    auth,
    users,
    accounts,
    loyalty,
    ewallet,
    transactions,
    dashboard,
    requestmoney,
    redux_actions,
})